import { styled } from '@mui/system'
import Button from '../button'

const ButtonStyled = styled(Button)`
	.MuiBadge-root {
		.MuiBadge-badge {
			font-size: ${({ theme }) => theme.typography.pxToRem(10)};
			width: 10px;
			height: 10px;
		}
		> .MuiSvgIcon-root {
			color: var(--color-primary);
			font-size: ${({ theme }) => theme.typography.pxToRem(26)};
			${({ theme }) => theme.breakpoints.up('md')} {
				font-size: ${({ theme }) => theme.typography.pxToRem(20)};
			}
		}
		.MuiBadge-badge .MuiSvgIcon-root {
			color: var(--color-primary);
			font-size: ${({ theme }) => theme.typography.pxToRem(12)};
		}
	}
`

export { ButtonStyled }
