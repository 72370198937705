/**
 * AUTO-GENERATED from generate-icons script 🤖 - DO NOT EDIT!
 */

import { forwardRef } from 'react'
import Icon from '../icon/icon'

const Bag2IconSvg = forwardRef((props, svgRef) => (
	<svg
		data-name="Livello 1"
		xmlns="http://www.w3.org/2000/svg"
		viewBox="0 0 24 24"
		width={props.width || (props.height && Math.floor(+props.height * 1)) || 24}
		height={props.height || (props.width && Math.floor(+props.width / 1)) || 24}
		fontSize="medium"
		ref={svgRef}
		{...props}
	>
		<path d="M19.49 20.5H4.51c-.54 0-1-.39-1.08-.92l-1.8-10.8A1.109 1.109 0 012.72 7.5H21.3a1.109 1.109 0 011.09 1.28l-1.8 10.8c-.09.53-.55.92-1.09.92zM2.71 8.5l-.1.12 1.8 10.8s.05.08.1.08h14.98s.09-.04.1-.08l1.8-10.8-.1-.12H2.71z" />
		<path d="M17.5 8h-1V6c0-.83-.67-1.5-1.5-1.5H9c-.83 0-1.5.67-1.5 1.5v2h-1V6A2.5 2.5 0 019 3.5h6A2.5 2.5 0 0117.5 6v2z" />
	</svg>
))
Bag2IconSvg.displayName = 'Bag2IconSvg'

const Bag2Icon = forwardRef((props, ref) => <Icon component={Bag2IconSvg} ref={ref} {...props} />)
Bag2Icon.displayName = 'Bag2Icon'

Bag2Icon.defaultProps = {
	...Icon.defaultProps,
}
Bag2Icon.propTypes = {
	...Icon.propTypes,
}

export default Bag2Icon
export { Bag2IconSvg }
