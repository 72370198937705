import { styled } from '@mui/system'
import MenuList from '@mui/material/MenuList'
import Button from '../../molecules/button'
import StorelocatorLink from '@bluheadless/ui/src/organisms/footer/storelocator-link'

const Root = styled(MenuList)`
	margin-top: auto;
	margin-bottom: 36px;
	padding: 0;
	padding-top: 83px;
	.MuiMenuItem-root {
		border: 0;
		padding: 0px;
		&:last-of-type {
			padding-bottom: 0;
		}
		&:hover {
			background: transparent;
		}
		.MuiLink-root {
			display: flex;
			align-items: center;
			&:hover {
				color: var(--color-grey30);
			}
			> button,
			> svg {
				margin-right: 0;
				display: none;
			}
		}
	}
`

const ButtonIcon = styled(Button)`
	padding: 0;
	.MuiSvgIcon-root {
		color: var(--color-primary);
		font-size: ${({ theme }) => theme.typography.pxToRem(18)};
	}
`

const StorelocatorLinkStyled = styled(StorelocatorLink)`
	color: var(--color-primary);
	font-size: ${({ theme }) => theme.typography.pxToRem(12)};
	letter-spacing: unset;
	&:hover {
		text-decoration: none;
	}
`

export { ButtonIcon, Root, StorelocatorLinkStyled }
