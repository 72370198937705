/**
 * AUTO-GENERATED from generate-icons script 🤖 - DO NOT EDIT!
 */

import { forwardRef } from 'react'
import Icon from '../icon/icon'

const Menu1IconSvg = forwardRef((props, svgRef) => (
	<svg
		data-name="Livello 1"
		xmlns="http://www.w3.org/2000/svg"
		viewBox="0 0 24 24"
		width={props.width || (props.height && Math.floor(+props.height * 1)) || 24}
		height={props.height || (props.width && Math.floor(+props.width / 1)) || 24}
		fontSize="medium"
		ref={svgRef}
		{...props}
	>
		<path d="M21 5.5H3c-.28 0-.5-.22-.5-.5s.22-.5.5-.5h18c.28 0 .5.22.5.5s-.22.5-.5.5zM21 12.5H3c-.28 0-.5-.22-.5-.5s.22-.5.5-.5h18c.28 0 .5.22.5.5s-.22.5-.5.5zM21 19.5H3c-.28 0-.5-.22-.5-.5s.22-.5.5-.5h18c.28 0 .5.22.5.5s-.22.5-.5.5z" />
	</svg>
))
Menu1IconSvg.displayName = 'Menu1IconSvg'

const Menu1Icon = forwardRef((props, ref) => <Icon component={Menu1IconSvg} ref={ref} {...props} />)
Menu1Icon.displayName = 'Menu1Icon'

Menu1Icon.defaultProps = {
	...Icon.defaultProps,
}
Menu1Icon.propTypes = {
	...Icon.propTypes,
}

export default Menu1Icon
export { Menu1IconSvg }
