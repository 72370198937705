/**
 * AUTO-GENERATED from generate-icons script 🤖 - DO NOT EDIT!
 */

import { forwardRef } from 'react'
import Icon from '../icon/icon'

const AccountIconSvg = forwardRef((props, svgRef) => (
	<svg
		data-name="Livello 1"
		xmlns="http://www.w3.org/2000/svg"
		viewBox="0 0 24 24"
		width={props.width || (props.height && Math.floor(+props.height * 1)) || 24}
		height={props.height || (props.width && Math.floor(+props.width / 1)) || 24}
		fontSize="medium"
		ref={svgRef}
		{...props}
	>
		<path d="M20 21.5c-.28 0-.5-.22-.5-.5v-2c0-.92-.37-1.82-1.03-2.47S16.92 15.5 16 15.5H8c-.93 0-1.81.36-2.48 1.03-.66.66-1.02 1.54-1.02 2.47v2c0 .28-.22.5-.5.5s-.5-.22-.5-.5v-2c0-1.2.47-2.33 1.32-3.18S6.8 14.5 8 14.5h8c1.18 0 2.34.48 3.18 1.32s1.32 2 1.32 3.18v2c0 .28-.22.5-.5.5zM12 11.5c-2.48 0-4.5-2.02-4.5-4.5S9.52 2.5 12 2.5s4.5 2.02 4.5 4.5-2.02 4.5-4.5 4.5zm0-8c-1.93 0-3.5 1.57-3.5 3.5s1.57 3.5 3.5 3.5 3.5-1.57 3.5-3.5-1.57-3.5-3.5-3.5z" />
	</svg>
))
AccountIconSvg.displayName = 'AccountIconSvg'

const AccountIcon = forwardRef((props, ref) => <Icon component={AccountIconSvg} ref={ref} {...props} />)
AccountIcon.displayName = 'AccountIcon'

AccountIcon.defaultProps = {
	...Icon.defaultProps,
}
AccountIcon.propTypes = {
	...Icon.propTypes,
}

export default AccountIcon
export { AccountIconSvg }
